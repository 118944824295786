var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-body-light"
  }, [_c('div', {
    staticClass: "content content-full"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
  }, [_c('h1', {
    staticClass: "flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2"
  }, [_vm._v("Create an account")]), _c('nav', {
    staticClass: "flex-sm-00-auto ml-sm-3",
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('ol', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb-item"
  }, [_vm._v("Entity")]), _c('li', {
    staticClass: "breadcrumb-item active",
    attrs: {
      "aria-current": "page"
    }
  }, [_vm._v("Create")])])])])])])]);

}]

export { render, staticRenderFns }